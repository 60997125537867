import { FunctionComponent } from "react";
import { MatterType } from "@/helpers/types";
import Link from "next/link";
import styles from "../../styles/components/MattersList/DesktopSpecialProject.module.scss";
import Img from "../Img";
import { usePlatform } from "@/helpers/platformContext";

const DesktopSpecialProject: FunctionComponent<{ matter: MatterType }> = ({
  matter,
}) => {
  const platform = usePlatform();
  const imageUrl =
    matter.image?.url || platform.stub_image.image_1920 || undefined;

  return (
    <Link href={matter.path}>
      <a className={styles.item}>
        <div className={styles.img}>
          <Img src={imageUrl} />
        </div>
        <div className={styles.text}>
          <div className={styles.label}>
            {matter.settings_custom?.label || "Спецпроект"}
          </div>
          <div className={styles.title}>{matter.title}</div>
          <div className={styles.lead}>{matter.lead}</div>
        </div>
      </a>
    </Link>
  );
};

export default DesktopSpecialProject;
